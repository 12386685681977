<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="600"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline"
        >{{ isEditing ? "Edit" : "Add a new" }} Option to Product</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="assign-form">
          <treeselect
            class="mb-4"
            :multiple="false"
            :options="sizesSelect"
            v-model="fields.size_id"
            :flat="true"
            :isDefaultExpanded="true"
            :default-expand-level="1"
            :show-count="false"
          />

          <v-text-field
            label="Regular Price (£) *"
            v-model="fields.price"
            type="number"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('price')"
            :error-messages="errors['price']"
          ></v-text-field>

          <v-text-field
            label="Sale Price (£)"
            v-model="fields.sale_price"
            type="number"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('sale_price')"
            :error-messages="errors['sale_price']"
          ></v-text-field>

          <v-select
            label="Stock Status*"
            :items="stockStatuses"
            item-text="text"
            item-value="value"
            v-model="fields.stock"
            hide-details
            :clearable="true"
            background-color="white"
            outlined
            dense
          ></v-select>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          :disabled="productLoading"
          form="assign-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      stockStatuses: [
        { text: "In Stock", value: "1" },
        { text: "Out of Stock", value: "0" },
      ],
      dialog: false,
      loading: false,
      productLoading: false,
      isEditing: false,
      option: {},
      product: {},
      fields: {
        price: null,
        stock: null,
        size_id: null,
        sale_price: null,
        selectedItem: null,
      },
      errors: {},
    };
  },

  computed: {
    sizesSelect() {
      let groups = this.$store.getters["eow/productsStore/groups"];

      groups = groups.map((group) => {
        let childExist = undefined;

        if (group.sizes.length > 0) {
          childExist = group.sizes.map((childCategory) => {
            return {
              id: childCategory.id,
              label: childCategory.name,
            };
          });
        }
        return {
          id: group.id,
          label: group.name,
          children: childExist,
        };
      });

      return groups;
    },
  },

  methods: {
    openForm: function (product, option = null) {
      this.product = product;

      if (option !== null) {
        this.isEditing = true;

        this.option = option.option;

        this.fields.price = option.price;
        this.fields.stock = option.stock;
      }

      this.dialog = true;
    },

    resetForm() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.option = {};
      this.errors = {};

      this.fields.price = null;
      this.fields.stock = null;
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        productId: this.$route.params.productId,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.optionId = this.option.id;
      }

      this.$store
        .dispatch("eow/productsStore/saveOptionProduct", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },
  },
};
</script>
